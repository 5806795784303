.images-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: var(--left-margin);
	min-width: var(--left-margin);
}
.image {
	margin: 2px;
	width: 20x;
	height: 20px;
}

@media (min-width: 576px) {
	.image {
		margin: 5px;
		width: 24px;
		height: 24px;
	}
}