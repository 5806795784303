.header {
	margin-left: var(--left-margin);
	display: none;
}

h1 {
	margin-top: 5px;
}

.functions {
	display: flex;
	flex-direction: column;
	flex: 80%;
	height: 100px;
	margin: 10px;
}

.navigation {
	margin-left: var(--left-margin);
	margin-top: 5px;
}

@media (min-width: 576px) {
	.header {
		display: block;
	}
	.navigation {
		margin-top: 20px;
	}
}
