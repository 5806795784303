.rank{
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px 5px 1px;
    border: var(--input-border);
}