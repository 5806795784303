.box{
    max-height: 30px;
    align-self: flex-start;
    margin-left: 15px;
    margin-top: 5px;
    
}
.option{
    display: none;
}
