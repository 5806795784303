.input {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	border-radius: var(--input-border-radius);
	border: var(--input-border);
	height: 25px;
	font-size: 14px;
	padding-left: 5px;
}
