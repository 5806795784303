.difficulty {
	font-family: "Courier New", monospace;
	font-size: 18px;
	font-weight: bold;
}
.difficulty-max {
	font-size: 26px;
	margin-right: 5px;
	margin-bottom: 0px;
}
