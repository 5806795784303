.spinner {
	display: inline-block;
	position: fixed;
	z-index: 9999;
}
.spinner:after {
	content: " ";
	display: block;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	border: 20px solid rgb(0, 143, 251);
	border-color: rgb(0, 143, 251) transparent rgb(0, 143, 251) transparent;
	animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
