.panel {
	height: 100%;
}
.manage-panel {
	margin-left: var(--left-margin);
	margin-right: calc(var(--right-margin) - 10px);
}

.visible-part {
	display: block;
	overflow: auto;
	height: 100%;
}

.not-visible-part {
	display: block;
	height: 0;
	overflow: hidden;
}
