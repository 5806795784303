.body-container {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.leaflet-container {
	width: 100%;
	height: 100%;
}

.leaflet-div-icon {
	background: transparent;
	border: none;
}

.map {
	height: 100%;
	width: 100%;
}

.hide-map {
	display: none;
}

.centeredSpinner {
	top: 0px;
	left: 0px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
}
.information {
	font-size: 20px;
	margin-top: 200px;
}

@media (min-width: 576px) {
	.body-container {
		flex-direction: row-reverse;
	}
	.map {
		display: block;
		height: 100vh;
		width: 100%;
	}
	.hide-map {
		display: block;
	}
	.leaflet-container {
		height: 100vh;
	}
}

@media (max-width: 768px) {
	.body-container {
		height: calc(100vh - 56px);
	}
}
