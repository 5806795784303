.div {
	display: flex;
}

.column {
	display: flex;
	flex-direction: column;
}

.table {
	width: 100%;
	border-spacing: 0px 2px;
}

.first-column {
	width: 400px;
	white-space: nowrap;
}

.panel-button {
	display: flex;
	justify-content: right;
}
