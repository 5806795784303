.point {
	display: flex;
	justify-items: center;
	margin: 2px 0px;
	border: var(--input-border);
	height: 40px;
	border-radius: var(--input-border-radius);
	font-size: 12px;
}

.delete {
	position:fixed;
	align-self: flex-end;
	margin-bottom: 5px;
	margin-left: 5px;
}

@media (min-width: 576px) {
	.point{
		height: 80px;
		font-size: 16px;
		margin: 5px 0px;
	}
}