.container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	border-top: var(--input-border);
	border-bottom: var(--input-border);
	border: var(--input-border);
	height: auto;
	border-radius: var(--input-border-radius);
}
