.main {
	flex-direction: column;
	display: flex;
	width: auto;
	max-height: 100vh;
}

.maxHeight{
	height: 100vh;
}

@media (min-width: 576px) {
	.main {
		min-width: 400px;
		width: 400px;
		height: 100vh;
	}
}
