.description {
	display: flex;
	flex-direction: row;
	margin: 0px 15px 5px;
}
.additional-info {
	width: 100%;
}
.empty {
	color: red;
	font-weight: bold;
	width: 100px;
	white-space: nowrap;
	margin-right: 5px;
}
